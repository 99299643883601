/* eslint-disable no-fallthrough */
/* eslint-disable no-useless-escape */
import DeviceTypes from '@enums/DeviceTypes';
import ReduxStatus from '@enums/ReduxStatus';
import { ReduxAction } from 'global';
import produce, { Draft } from 'immer';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_ERROR,
  SAVE_CAMPAIGN,
  SAVE_CAMPAIGN_ERROR,
  SAVE_CAMPAIGN_SUCCESS,
  RESET,
  REORDER_PAGE_STEP,
  UPDATE_CAMPAIGN_ALL_PAGE_CANVAS_ELEMENT_ATTRIBUTE,
  UPDATE_CAMPAIGN_TEASER_CANVAS_ELEMENT_ATTRIBUTE,
  UPDATE_HIGHLIGHT_MODE,
  INCREASE_PUBLISH_VERSION,
  TOGGLE_CANVAS_DRAG_DROP_STATUS,
  UPDATE_CAMPAIGN_DOMAIN_VERIFIED_STATUS,
  UPDATE_PUBLISH_LOADING_STATUS,
  SET_EMAIL_AUTOMATION_EDIT,
  SET_TOTAL_CAMPAIGN_COUNT,
} from './constants';

import type {
  BuilderState,
  GetCampaignSuccessAction,
  IntegrationType,
  IntegrationTypeArray,
  SaveCampaignSuccessAction,
  TargetsKey,
} from './types';
import guid from '@utils/guid';
import hash from '@utils/hash';
import type { Node, Content, Campaign, Attributes } from './campaign';
import CustomizeSidebarTypes from '@enums/CustomizeSidebarTypes';
import {
  UPDATE_CAMPAIGN_PAGE_ATTRIBUTE,
  UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE,
  UPDATE_CAMPAIGN_PAGE_CONTENT_ATTRIBUTE,
  ADD_CAMPAIGN_CONTENT_ELEMENT,
  UPDATE_CAMPAIGN_CONTENT_ELEMENT,
  MOVE_CAMPAIGN_CONTENT_ELEMENT,
  UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE,
  REMOVE_CAMPAIGN_CONTENT_ELEMENT,
  UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE,
  UPDATE_CAMPAIGN_CURRENT_PAGE_ID,
  ADD_CAMPAIGN_PAGE,
  REMOVE_CAMPAIGN_PAGE,
  UPDATE_CAMPAIGN_PREVIEW,
  UPDATE_VISITOR_DEVICE,
  REVERSE_CAMPAIGN_CHILD_ELEMENTS,
  UPDATE_CAMPAIGN_PAGE_FONT_FAMILY,
  UPDATE_CAMPAIGN_PAGE_COLOR,
  UPDATE_CUSTOMIZE_SIDEBAR,
  UPDATE_INLINE_EDITOR,
  CLONE_CAMPAIGN_CONTENT_ELEMENT,
  COPY_DESKTOP_TO_MOBILE,
  SET_CUSTOMIZE_COPY,
  RESET_CUSTOMIZE_COPY,
  ORJ_DATA_TO_DATA,
  UPDATE_HOVER_ID,
  UPDATE_PAGE_CONTENT,
  RESET_CAMPAIGN_TEASER_TO_ORJDATA,
} from './customize/constants';
import { SET_TARGET_ERROR, DELETE_TARGET_ERROR } from './target/constants';
import {
  SET_INTEGRATIONS,
  ADD_INTEGRATION,
  CHANGE_STATUS_INTEGRATION,
  DELETE_INTEGRATION,
  DISABLE_ALL_INTEGRATIONS,
  DOMAIN_DELETE,
  DOMAIN_DELETE_SUCCESS,
  DOMAIN_SELECT,
  DOMAIN_SELECT_SUCCESS,
  LOCAL_UPDATE_CAMPAING,
  UPDATE_AUTO_RESPONDER_FAILURE,
  UPDATE_AUTO_RESPONDER_REQUEST,
  UPDATE_AUTO_RESPONDER_SUCCESS,
  UPDATE_INTEGRATION,
} from './settings/constants';
import IntegrationEnums from '@enums/IntegrationEnums';
import uniq from 'lodash/uniq';
import { hex2rgb, hex2rgbArray } from '@utils/color';
import {
  randomCodeGenerateWithPrefix,
  randomId,
  rgbToHex,
} from 'core/helpers/string';
import isEmpty from 'lodash/isEmpty';
import { RESET_STORE } from '@connectors/actions';
import {
  CLEAR_PREVIEW,
  SET_PREVIEW,
  GET_CURRENT_TEMPLATE_SUCCESS,
  SET_STYLE,
  SET_THEME,
} from './style/constants';
import ThemeList, { attributeList } from '@components/StyleSidebar/ThemeList';
import { formNodeNameList } from '@elements/SmartTag/common/StaticVariables';
import { NodeType } from '@model/types';
import Layouts from '@model/Layout';
import StepTypes from '@enums/StepTypes';
import { resizePopupLayout } from '@utils/resizePopupLayout';

import { teaserMigrations } from '@utils/teaserLayoutMigrations';

export const initialState: BuilderState = {
  status: ReduxStatus.initialized,
  data: {
    id: 0,
    domains: [],
    name: '',
    mobileScreenshot: null,
    desktopScreenshot: null,
    publishLoadingStatus: null,
  },
  preview: true,
  customizeSidebar: {
    type: CustomizeSidebarTypes.tree,
    isAccordionElement: false,
  },
  isCanvasDragDropActive: false,
  inlineEditor: {},
  highlight: {
    mode: 'default',
  },
  emailAutomationEdit: null,
  totalCampaignCount: 0,
};

export const setLayoutTheme = (data: any, themeCode: any) => {
  const { layout } = data;
  const theme = ThemeList.find((x) => x.code === themeCode);

  if (!layout.style) {
    if (theme) {
      layout.style = {
        code: theme.code,
        data: theme.data,
      };
    }
  }
  if (layout.style && !layout.style.data.element) {
    layout.style.data = {
      ...layout.style.data,
      element: {
        fontSize: '14px',
        lineHeight: '20px',
        paddingVertical: '8px',
        paddingHorizontal: '11px',
        iconWidth: '18px',
        iconHeight: '18px',
        markerWidth: '20px',
        markerHeight: '20px',
      },
    };
  }
  if (layout.style && !layout.style.data.form.error) {
    layout.style.data = {
      ...layout.style.data,
      form: {
        ...layout.style.data.form,
        error: '#ee0c0c',
      },
    };
  }

  if (
    layout.style &&
    (!layout.style.data.teaser || isEmpty(layout.style.data.teaser))
  ) {
    layout.style.data = {
      ...layout.style.data,
      teaser: {
        ...theme?.data.teaser,
      },
    };
  }
  return data;
};

const countdownMigration = (element: Node) => {
  if (element.attributes.type === 'fixed') {
    element.attributes.type = 'dynamic';
  }
  if (element.attributes.showDays === undefined) {
    element.attributes.showDays = element.attributes.days !== 0;
  }
  if (element.attributes.showHours === undefined) {
    element.attributes.showHours = element.attributes.hours !== 0;
  }
  if (element.attributes.showMinutes === undefined) {
    element.attributes.showMinutes = element.attributes.minutes !== 0;
  }
  if (element.attributes.showSeconds === undefined) {
    element.attributes.showSeconds = element.attributes.seconds !== 0;
  }
  if (element.attributes.timezone === undefined) {
    element.attributes.timezone = 'Europe/London';
  }
  if (element.attributes.fixedDate === undefined) {
    const now = new Date();
    const tomorrow = new Date(now.setDate(now.getDate() + 1));
    const midnight = tomorrow.setHours(0, 0, 0);
    element.attributes.fixedDate = new Date(midnight);
  }
  if (element.attributes.message === undefined) {
    element.attributes.message = '';
  }
  return element;
};

const emailInputMigration = (element: Node) => {
  if (element.attributes.validationMessage === undefined) {
    element.attributes.validationMessage = 'Enter a valid email address';
  }

  return element;
};

const phoneInputMigration = (element: Node) => {
  if (element.attributes.validationMessage === undefined) {
    element.attributes.validationMessage = 'Enter a valid phone number';
  }

  return element;
};
export const deleteAttributes = (data: Campaign) => {
  const { layout } = data;
  const deleteAttribute = (childNode: Node) => {
    if (childNode['attributes']['style']) {
      //TODO I don't want to waste more time, we will fix later
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(childNode['attributes']['style']).forEach((x: any) => {
        const nodeName = childNode['nodeName'];
        if (
          nodeName &&
          attributeList[nodeName]?.findIndex((y: string) => x === y) === -1
        ) {
          delete childNode.attributes.style[x];
        }
      });
    }

    if (
      Object.prototype.hasOwnProperty.call(childNode, 'childNodes') &&
      childNode['childNodes'].length > 0
    ) {
      childNode['childNodes'].forEach((cn) => {
        if (
          typeof cn === 'object' &&
          (Object.prototype.hasOwnProperty.call(cn, 'attributes') ||
            Object.prototype.hasOwnProperty.call(cn, 'childNodes'))
        )
          deleteAttribute(cn);
      });
    }
  };
  if (layout?.style) {
    layout['contents'].forEach((element) => {
      deleteAttribute(element['content']['desktop']);
      deleteAttribute(element['content']['mobile']);
    });
  }
  return data;
};

const reducer = produce((draft: Draft<BuilderState>, action: ReduxAction) => {
  const { type } = action;
  switch (type) {
    case GET_CAMPAIGN: {
      draft.status = ReduxStatus.loading;
      return;
    }
    case GET_CAMPAIGN_SUCCESS: {
      const { payload } = action as GetCampaignSuccessAction;
      const { loadCustomizeCopy } = payload;
      let { data } = payload;
      let haveTeaser = false;
      if (
        data.layout?.contents !== undefined &&
        !!data.layout?.contents.find((x) => x.type === StepTypes.TEASER)
      ) {
        haveTeaser = true;
      }
      if (!haveTeaser && data.layout?.contents !== undefined) {
        const resizedTeaserLayout = resizePopupLayout({
          page: Layouts.teaser[0].layout!,
          currentSize: Layouts.teaser[0].layout?.appearance.size as
            | 'sm'
            | 'md'
            | 'lg',
          toSize: data.layout?.contents[0].appearance.size as
            | 'sm'
            | 'md'
            | 'lg',
        });
        const defaultTeaser = cloneDeep(resizedTeaserLayout) as Content;
        defaultTeaser.template = {
          name: Layouts.teaser[0].key,
          id: 0,
          imageUrl: '',
        };
        defaultTeaser.appearance.visibility = false;
        defaultTeaser.id = guid();
        data.layout?.contents.unshift(defaultTeaser);
      }
      if (
        haveTeaser &&
        data.layout?.contents &&
        data.layout?.contents?.length > 0
      ) {
        const migratedTeaser = teaserMigrations(data.layout?.contents[0]);

        data.layout.contents[0] = migratedTeaser;
      }
      data = setLayoutTheme(data, 'simple');
      //countdown,emailInput,phoneInput migrations
      if (data.layout && data.layout.contents) {
        data = {
          ...data,
          layout: {
            ...data.layout,
            contents: findAndReplaceByNodeName(data.layout?.contents, {
              countdown: countdownMigration,
              'email-input': emailInputMigration,
              'phone-input': phoneInputMigration,
            }),
          },
        };
      }
      //TODO It will be opened after manage attributes list
      //data = deleteAttributes(data);
      const isDesktopTargeted: boolean =
        data.targets?.visitorDevice?.desktop || true;

      // @ts-ignore TODO
      draft.orjData = data;
      // @ts-ignore TODO
      if (loadCustomizeCopy) draft.data = data;
      draft.status = ReduxStatus.success;
      if (data.layout?.contents) {
        if (data.layout?.contents?.length > 1) {
          draft.currentPageId = data.layout?.contents[1].id;
        } else {
          draft.currentPageId = data.layout?.contents[0].id;
        }
      }
      draft.currentDeviceType = isDesktopTargeted
        ? DeviceTypes.desktop
        : DeviceTypes.mobile;
      return;
    }
    case GET_CAMPAIGN_ERROR: {
      draft.status = ReduxStatus.errored;
      return;
    }
    //#region Customize Start
    case SET_CUSTOMIZE_COPY: {
      const { payload } = action;
      if (draft) draft.customizeCopyData = payload.data;
      return;
    }
    case ORJ_DATA_TO_DATA: {
      const { orjData } = draft;
      if (orjData) draft.data = orjData;
      return;
    }
    case RESET_CUSTOMIZE_COPY: {
      if (draft.data) delete draft.data;
      return;
    }
    case UPDATE_CAMPAIGN_PAGE_ATTRIBUTE: {
      const { payload } = action;
      const { attributePath, value, pageId } = payload;

      const attributePathArr: string[] = attributePath.split('.');
      if (attributePathArr.length <= 0 || !(draft.data && draft.data.layout))
        return;
      const lastElement = attributePathArr.pop();
      if (!lastElement) return;
      if (pageId) {
        const selectedPageId = pageId;
        const currentContent = draft.data.layout.contents.find(
          (x) => x.id === selectedPageId,
        );
        if (!currentContent) return;
        let data;
        if (attributePathArr.length === 0) data = currentContent;
        else data = get(currentContent, attributePathArr.join('.'));
        const oldData = data[lastElement];
        data[lastElement] = value;

        if (lastElement === 'size') {
          updateSizes({
            theObject: draft.data.layout,
            currentSize: oldData,
            toSize: data[lastElement],
          });
        }
      } else {
        draft.data.layout?.contents.forEach((content) => {
          if (!content) return;

          const isInlineFloatingBar =
            content.type === StepTypes.FLOATING && content.isInline;

          if (
            attributePath === 'appearance.displayEffect.type' &&
            isInlineFloatingBar
          )
            return;

          let data;
          if (attributePathArr.length === 0) data = content;
          else data = get(content, attributePathArr.join('.'));
          data[lastElement] = value;
        });
      }

      return;
    }
    case UPDATE_CAMPAIGN_CONTENT_ATTRIBUTE: {
      const { payload } = action;
      const { attributePath, value } = payload;
      const { currentPageId, currentDeviceType } = draft;
      const attributePathArr: string[] = attributePath.split('.');
      if (
        attributePathArr.length <= 0 ||
        !(draft.data && draft.data.layout) ||
        !currentDeviceType
      )
        return;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === currentPageId,
      );
      if (!currentContent) return;
      const lastElement = attributePathArr.pop();
      if (!lastElement) return;
      let data;
      if (attributePathArr.length === 0)
        data = currentContent.content[currentDeviceType];
      else
        data = get(
          currentContent.content[currentDeviceType],
          attributePathArr.join('.'),
        );

      data[lastElement] = value;
      return;
    }
    case UPDATE_CAMPAIGN_PAGE_CONTENT_ATTRIBUTE: {
      const { payload } = action;
      const { attributePath, value, pageId, deviceType } = payload;
      const activeDeviceType = deviceType as DeviceTypes;

      const attributePathArr: string[] = attributePath.split('.');
      if (
        attributePathArr.length <= 0 ||
        !(draft.data && draft.data.layout) ||
        !activeDeviceType
      )
        return;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === pageId,
      );
      if (!currentContent) return;
      const lastElement = attributePathArr.pop();
      if (!lastElement) return;
      let data;
      if (attributePathArr.length === 0)
        data = currentContent.content[activeDeviceType];
      else
        data = get(
          currentContent.content[activeDeviceType],
          attributePathArr.join('.'),
        );
      data[lastElement] = value;
      return;
    }
    case ADD_CAMPAIGN_CONTENT_ELEMENT: {
      const { payload } = action;
      const { id, value, index, showFormEditor, switchEditFormElementSidebar } =
        payload;
      const { currentPageId, currentDeviceType } = draft;
      if (!currentDeviceType || !(draft.data && draft.data.layout)) return;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === currentPageId,
      );
      if (!currentContent) return;

      let idsObj = {
        status: false,
        data: [],
      } as { status: boolean; data: { oldId: string; newId: string }[] };
      let newObjId = '';
      for (const deviceType in currentContent.content) {
        const { newObject, ids } = generateAllIds({
          theObject: cloneDeep(value),
          ids: idsObj,
        });
        newObjId = newObject.id;
        const element = getObject(
          currentContent.content[deviceType as DeviceTypes],
          id,
        );
        element.childNodes.splice(index, 0, newObject);
        ids.status = true;
        idsObj = ids;
      }
      //if the inserted element is from
      if (value.nodeName === 'form') {
        draft.customizeSidebar.type = CustomizeSidebarTypes.editFormElement;
        draft.customizeSidebar.activeElementId = newObjId;
        draft.customizeSidebar.id = newObjId;
      }
      // if the inserted element is the form element
      if (showFormEditor && [...formNodeNameList].includes(value.nodeName)) {
        draft.customizeSidebar.type = CustomizeSidebarTypes.editFormElement;
        if (!draft.customizeSidebar.id) {
          draft.customizeSidebar.id = idsObj.data[0].newId;
        }
        draft.customizeSidebar.activeElementId = idsObj.data[0].newId;
      }
      if (switchEditFormElementSidebar) {
        draft.customizeSidebar.type = CustomizeSidebarTypes.editFormElement;
        const currentNode = draft.data.layout.contents.find(
          (x) => x.id === currentPageId,
        )?.content[currentDeviceType];

        type ObjectChildNodeType = {
          id: string;
          nodeName: NodeType;
          attributes: Attributes;
          childNodes: ObjectChildNodeType[];
        };
        const isOneColumnPopup =
          (currentNode?.childNodes as ObjectChildNodeType[]).filter(
            (node) => node.nodeName === 'block',
          ).length === 0;
        let nodes = isOneColumnPopup
          ? currentNode?.childNodes
          : (currentNode?.childNodes as ObjectChildNodeType[]).flatMap(
              (node) => node.childNodes,
            );
        const activeformElementInContent = (
          nodes as ObjectChildNodeType[]
        ).find((node) => node.nodeName === 'form');
        if (!activeformElementInContent) return;
        draft.customizeSidebar.id = activeformElementInContent.id;
      }
      return;
    }
    case UPDATE_CAMPAIGN_CONTENT_ELEMENT: {
      const { payload } = action;
      const { id, value } = payload;
      const { currentPageId, currentDeviceType } = draft;
      if (!currentDeviceType || !(draft.data && draft.data.layout)) return;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === currentPageId,
      );
      if (!currentContent) return;
      for (const deviceType in currentContent.content) {
        currentContent.content[deviceType as DeviceTypes] = updateProperties({
          theObject: currentContent.content[deviceType as DeviceTypes],
          id,
          propertyName: 'childNodes',
          propertyValue: value,
        });
      }
      return;
    }
    case MOVE_CAMPAIGN_CONTENT_ELEMENT: {
      const { payload } = action;
      const { id, toId, index, topElementId } = payload;
      if (!(draft.data && draft.data.layout && draft.currentPageId)) return;
      const content = draft.data.layout.contents.find(
        (x) => x.id === draft.currentPageId,
      );
      if (!content) return;
      for (const deviceType in content.content) {
        const element = cloneDeep(
          getObject(content.content[deviceType as DeviceTypes], id),
        );
        content.content[deviceType as DeviceTypes] = removeProperties({
          theObject: content.content[deviceType as DeviceTypes],
          id,
        });
        const topElement = getObject(
          content.content[deviceType as DeviceTypes],
          topElementId ?? toId,
        );

        topElement.childNodes.splice(index, 0, element);
      }

      return;
    }
    case UPDATE_CAMPAIGN_CONTENT_ELEMENT_ATTRIBUTE: {
      const { payload } = action;
      const { id, attributePath, value, isLinked } = payload;
      const { currentPageId, currentDeviceType } = draft;
      const attributePathArr: string[] = attributePath.split('.');

      if (attributePathArr.length <= 0 || !(draft.data && draft.data.layout))
        return;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === currentPageId,
      );
      if (!currentContent) return;
      const lastField = attributePathArr.pop();
      if (isLinked) {
        for (const deviceType in currentContent.content) {
          const element = getObject(
            currentContent.content[deviceType as DeviceTypes],
            id,
          );
          let data = element;
          if (attributePathArr.length !== 0)
            data = get(element, attributePathArr.join('.'));
          if (!lastField || !data) return;
          data[lastField] = value;
        }
      } else if (currentDeviceType) {
        const element = getObject(
          currentContent.content[currentDeviceType],
          id,
        );
        let data = element;
        if (attributePathArr.length !== 0)
          data = get(element, attributePathArr.join('.'));
        if (!lastField || !data) return;
        data[lastField] = value;
      }

      return;
    }

    case UPDATE_CAMPAIGN_ALL_PAGE_CANVAS_ELEMENT_ATTRIBUTE: {
      if (!draft.data?.layout) return;
      const { payload } = action;
      const { newAttributes } = payload;

      draft.data.layout.contents.forEach((page) => {
        const isPageTeaser = page.type === StepTypes.TEASER;
        if (isPageTeaser) return;

        for (const deviceType in page.content) {
          if (draft.data?.layout) {
            const canvasAttributes =
              page.content[deviceType as DeviceTypes].attributes;

            const canvasAttributeStyle =
              page.content[deviceType as DeviceTypes].attributes.style;
            Object.assign(canvasAttributeStyle, newAttributes.style);

            if (newAttributes?.style) {
              delete newAttributes.style;
            }
            Object.assign(canvasAttributes, newAttributes);
          }
        }
      });
      return;
    }
    case UPDATE_CAMPAIGN_TEASER_CANVAS_ELEMENT_ATTRIBUTE: {
      if (!draft.data?.layout) return;
      const { payload } = action;
      const { newAttributes } = payload;

      draft.data.layout.contents.forEach((page) => {
        const isPageTeaser = page.type === StepTypes.TEASER;
        if (!isPageTeaser) return;

        for (const deviceType in page.content) {
          if (draft.data?.layout) {
            const canvasAttributes =
              page.content[deviceType as DeviceTypes].attributes;

            const canvasAttributeStyle =
              page.content[deviceType as DeviceTypes].attributes.style;
            Object.assign(canvasAttributeStyle, newAttributes.style);
            if (newAttributes?.style) {
              delete newAttributes.style;
            }
            Object.assign(canvasAttributes, newAttributes);
          }
        }
      });
      return;
    }
    case REMOVE_CAMPAIGN_CONTENT_ELEMENT: {
      const { payload } = action;
      const { id } = payload;
      if (!(draft.data && draft.data.layout)) return;
      const { currentPageId } = draft;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === currentPageId,
      );
      if (!currentContent) return;
      for (const deviceType in currentContent.content) {
        currentContent.content[deviceType as DeviceTypes] = removeProperties({
          theObject: currentContent.content[deviceType as DeviceTypes],
          id,
        });
      }
      if (
        draft.customizeSidebar.type === CustomizeSidebarTypes.editFormElement
      ) {
        draft.customizeSidebar.activeElementId = draft.customizeSidebar.id;
      }
      return;
    }
    case UPDATE_CAMPAIGN_CURRENT_DEVICE_TYPE: {
      const { payload } = action;
      const { deviceType } = payload;
      if (Object.values(DeviceTypes).includes(deviceType))
        draft.currentDeviceType = payload.deviceType;
      return;
    }
    case UPDATE_CAMPAIGN_CURRENT_PAGE_ID: {
      const { payload } = action;
      const {
        pageId,
        clearCustomizeSidebarActiveElementId = false,
        isAccordionElement = false,
      } = payload;

      if (draft.data?.layout?.contents.some((x) => x.id === pageId)) {
        draft.currentPageId = pageId;
        draft.customizeSidebar.isAccordionElement = isAccordionElement;
        if (
          clearCustomizeSidebarActiveElementId === true &&
          draft.customizeSidebar?.activeElementId
        ) {
          delete draft.customizeSidebar.activeElementId;
        }
      }
      return;
    }
    case ADD_CAMPAIGN_PAGE: {
      const { payload } = action;
      const { title, pageId, contents } = payload;
      if (!(draft.data && draft.data.layout)) return;
      if (pageId) {
        const currentContent = draft.data.layout.contents.find(
          (x) => x.id === pageId,
        );
        const clonedContent = cloneDeep(currentContent);
        if (!clonedContent) return;
        clonedContent.id = guid();
        clonedContent.title = title;
        let idsObj = {
          status: false,
          data: [],
        } as { status: boolean; data: { oldId: string; newId: string }[] };

        const generateRandomStrings = (nodeName: string) => {
          const newRandomName = randomCodeGenerateWithPrefix(nodeName);
          const newRandomId = randomId();
          const result = {
            newRandomName,
            newRandomId,
          };
          return result;
        };

        let duplicatedFormChildNodes = [] as Node[];
        const findFormNodeAndGenerateName = <T extends Node>(node: T): T => {
          if (node.childNodes && Array.isArray(node.childNodes)) {
            for (let i = 0; i < node.childNodes.length; i++) {
              const childNode = node.childNodes[i] as Node;
              let foundNode = findFormNodeAndGenerateName(childNode);

              if (foundNode.nodeName === 'form') {
                if (duplicatedFormChildNodes.length > 0) {
                  const formNodeIndex = (node.childNodes as Node[]).findIndex(
                    (node) => node.nodeName === 'form',
                  );
                  (node.childNodes as Node[])[formNodeIndex].childNodes =
                    duplicatedFormChildNodes;
                  continue;
                }

                (foundNode.childNodes as Node[]).forEach((node) => {
                  if (node.nodeName === 'button-wrapper') return;
                  const randomStrings = generateRandomStrings(
                    node.nodeName.replace('-', '_'),
                  );

                  node.attributes.name = randomStrings.newRandomName;
                  node.attributes.id = `${node.nodeName}-${randomStrings.newRandomId}`;
                  node.id = randomStrings.newRandomId;
                });
                duplicatedFormChildNodes = foundNode.childNodes as Node[];
              }
            }
          }
          return node;
        };

        for (const deviceType in clonedContent.content) {
          const { newObject, ids } = generateAllIds({
            theObject: clonedContent.content[deviceType as DeviceTypes],
            ids: idsObj,
          });
          clonedContent.content[deviceType as DeviceTypes] =
            findFormNodeAndGenerateName(newObject);

          ids.status = true;
          idsObj = ids;
        }
        draft.data.layout.contents.push(clonedContent);
        // switch to newly created page
        draft.currentPageId = clonedContent.id;
        return;
      } else if (contents) {
        const oldPageIds: string[] = [];
        const newPageIds: string[] = [];
        let newContents: Content[] = [];
        contents.forEach((content: Content, index: number) => {
          const clonedContent = cloneDeep(content);
          let idsObj = {
            status: false,
            data: [],
          } as { status: boolean; data: { oldId: string; newId: string }[] };
          oldPageIds.push(clonedContent.id);
          clonedContent.id = guid();
          newPageIds.push(clonedContent.id);
          for (const deviceType in clonedContent.content) {
            const { newObject, ids } = generateAllIds({
              theObject: clonedContent.content[deviceType as DeviceTypes],
              ids: idsObj,
            });
            if (index === 0) clonedContent.title = title;
            else {
              // check if last part of the name is number. if it is number add 1.
              const secondStepName = !isNaN(Number(title.match(/\d+$/)?.[0]))
                ? title.replace(
                    title.match(/\d+$/)?.[0]!,
                    `${Number(title.match(/\d+$/)?.[0]) + index}`,
                  )
                : `${title}-${index + 1}`;
              clonedContent.title = secondStepName;
            }
            clonedContent.content[deviceType as DeviceTypes] = newObject;
            ids.status = true;
            idsObj = ids;
          }
          newContents.push(clonedContent);
        });
        const { newObject } = replaceValues({
          theObject: newContents,
          oldValues: oldPageIds,
          newValues: newPageIds,
        });
        newContents = newObject;
        // @ts-ignore TODO
        draft.data?.layout?.contents.push(...newContents);
        // switch to newly created page
        draft.currentPageId = newPageIds[newPageIds.length - 1];
        return;
      }
      return;
    }
    case CLONE_CAMPAIGN_CONTENT_ELEMENT: {
      const { payload } = action;
      const { id } = payload;
      const { currentPageId } = draft;
      if (!(draft.data && draft.data.layout && currentPageId && id)) return;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === currentPageId,
      );
      if (!currentContent) return;
      let idsObj = {
        status: false,
        data: [],
      } as { status: boolean; data: { oldId: string; newId: string }[] };
      const clonedContent = {
        mobile: {},
        desktop: {},
      };
      for (const deviceType in currentContent.content) {
        const element = getObject(
          currentContent.content[deviceType as DeviceTypes],
          id,
        );
        clonedContent[deviceType as DeviceTypes] = cloneDeep(element);
      }
      for (const deviceType in clonedContent) {
        const { ids } = generateAllIds({
          theObject: clonedContent[deviceType as DeviceTypes],
          ids: idsObj,
        });

        ids.status = true;
        idsObj = ids;
      }

      for (const deviceType in currentContent.content) {
        if (payload?.isFormElement) {
          clonedContent[deviceType as DeviceTypes] = produce(
            clonedContent[deviceType as DeviceTypes] as Node,
            (draft) => {
              draft.attributes.name = randomCodeGenerateWithPrefix(
                draft.nodeName,
              );
            },
          );
        }

        const parentObject = getParentObject(
          currentContent.content[deviceType as DeviceTypes],
          id,
        );
        const currentObjectIndex = parentObject.childNodes
          .map((x: Node) => x.id)
          .indexOf(id);
        parentObject.childNodes.splice(
          currentObjectIndex + 1,
          0,
          clonedContent[deviceType as DeviceTypes],
        );
      }
      draft.customizeSidebar.activeElementId = (
        clonedContent[draft.currentDeviceType as DeviceTypes] as Node
      ).id;
      return;
    }
    case REMOVE_CAMPAIGN_PAGE: {
      const { payload } = action;
      const { pageId } = payload;
      if (!(draft.data && draft.data.layout) || !pageId) return;
      const pageIndex = draft.data.layout.contents
        .map((x) => x.id)
        .indexOf(pageId);
      draft.data.layout.contents.splice(pageIndex, 1);
      return;
    }
    case REORDER_PAGE_STEP: {
      const { pageSteps } = action.payload;
      if (pageSteps && draft.data?.layout?.contents) {
        const firstItem = draft.data.layout.contents[0];
        draft.data.layout.contents = [firstItem, ...pageSteps];
      }
      return;
    }
    case UPDATE_CAMPAIGN_PREVIEW: {
      const { payload } = action;
      const { isActive } = payload;
      draft.preview = isActive;
      return;
    }
    case TOGGLE_CANVAS_DRAG_DROP_STATUS: {
      const { payload } = action;
      const { isActive } = payload;
      if (draft.isCanvasDragDropActive === isActive) return;

      draft.isCanvasDragDropActive = isActive;
      return;
    }
    case UPDATE_VISITOR_DEVICE: {
      const { payload } = action;
      const { mobile, desktop } = payload;
      if (!(draft.data && draft.data?.targets)) return;
      draft.data.targets.visitorDevice = {
        mobile,
        desktop,
      };
      return;
    }
    case REVERSE_CAMPAIGN_CHILD_ELEMENTS: {
      const { payload } = action;
      const { id } = payload;
      const { currentPageId, currentDeviceType } = draft;
      if (!currentDeviceType || !(draft.data && draft.data.layout)) return;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === currentPageId,
      );
      if (!currentContent) return;
      const element: Node = getObject(
        currentContent.content[currentDeviceType],
        id,
      );
      if (element.childNodes.length <= 0) return;
      if (typeof element.childNodes[0] === 'string') return;

      if (
        typeof (element.childNodes[0] as Node).attributes.style['order'] ===
        'undefined'
      ) {
        (element.childNodes as Node[]).forEach((cN: Node, index: number) => {
          // @ts-ignore TODO
          cN.attributes.style['order'] = element.childNodes.length - 1 - index;
        });
      } else {
        (element.childNodes as Node[]).forEach((cN: Node) => {
          delete cN.attributes.style['order'];
        });
      }
      return;
    }
    case UPDATE_CAMPAIGN_PAGE_FONT_FAMILY: {
      const { payload } = action;
      const { value, pageId } = payload;
      const { currentPageId } = draft;
      if (!value || !(draft.data && draft.data.layout)) return;
      let selectedPageId = currentPageId;
      if (pageId) selectedPageId = pageId;
      const currentContent = draft.data.layout.contents.find(
        (x) => x.id === selectedPageId,
      );
      if (!currentContent) return;
      updateFontFamilies({
        theObject: currentContent,
        fontFamily: value,
      });

      return;
    }
    case UPDATE_CAMPAIGN_PAGE_COLOR: {
      const { payload } = action;
      const { oldValue, value } = payload;

      if (!oldValue || !value || !(draft.data && draft.data.layout)) return;
      draft.data.layout.contents.forEach((page) => {
        for (const deviceType in page.content) {
          page.content[deviceType as DeviceTypes] = updateColor({
            theObject: page.content[deviceType as DeviceTypes],
            oldColor: oldValue,
            color: value,
          });
        }
      });

      return;
    }
    case UPDATE_CUSTOMIZE_SIDEBAR: {
      const { payload } = action;
      const { type, id, activeElementId, addElementIndex } = payload;
      draft.customizeSidebar.type = type;
      if (activeElementId) {
        draft.customizeSidebar.activeElementId = activeElementId;
      }

      if (
        type === CustomizeSidebarTypes.add ||
        type === CustomizeSidebarTypes.edit ||
        type === CustomizeSidebarTypes.addFormElement ||
        type === CustomizeSidebarTypes.editFormElement ||
        type === CustomizeSidebarTypes.editShopifyElement ||
        id
      ) {
        draft.customizeSidebar.id = id;
        if (addElementIndex !== undefined) {
          draft.customizeSidebar.addElementIndex = addElementIndex;
        }
      } else {
        delete draft.customizeSidebar.id;
        delete draft.customizeSidebar.activeElementId;
        delete draft.customizeSidebar.addElementIndex;
      }
      return;
    }

    case UPDATE_HOVER_ID: {
      const { payload } = action;
      const { id } = payload;
      draft.hoverId = id;
      return;
    }
    case UPDATE_INLINE_EDITOR: {
      const { payload } = action;
      const { id } = payload;
      if (id) draft.inlineEditor.id = id;
      else delete draft.inlineEditor.id;
      return;
    }
    case SAVE_CAMPAIGN: {
      draft.status = ReduxStatus.loading;
      return;
    }
    case SAVE_CAMPAIGN_SUCCESS: {
      const { payload } = action as SaveCampaignSuccessAction;
      const { data, updateTargetCopy } = payload;
      if (updateTargetCopy) {
        // @ts-ignore TODO
        draft.orjData.targets = data.targets;
      } else {
        // @ts-ignore TODO
        draft.orjData = data;
      }
      draft.status = ReduxStatus.success;

      const isCurrentSidebarTypeShouldStayCurrentPage = [
        CustomizeSidebarTypes.editFormElement,
        CustomizeSidebarTypes.editShopifyElement,
      ].includes(draft.customizeSidebar.type);

      if (
        !isCurrentSidebarTypeShouldStayCurrentPage &&
        data.layout?.contents &&
        data.layout?.contents?.length >= 0
      ) {
        if (data.layout?.contents[0].appearance.visibility) {
          if (data.layout?.contents?.length > 1) {
            draft.currentPageId = data.layout?.contents[1].id;
          } else {
            draft.currentPageId = data.layout?.contents[0].id;
          }
        }
      }
      /* draft.currentDeviceType = isDesktopTargeted
                    ? DeviceTypes.desktop
                    : DeviceTypes.mobile; */
      if (updateTargetCopy && draft.data) draft.data.targets = data.targets;
      //if (updateTargetCopy) draft.targetsCopyData = data.targets;
      return;
    }
    case SAVE_CAMPAIGN_ERROR: {
      draft.status = ReduxStatus.errored;
      return;
    }
    case INCREASE_PUBLISH_VERSION: {
      if (draft.orjData) {
        draft.orjData.publishVersion = (draft.orjData?.publishVersion ?? 0) + 1;
        draft.orjData.status = true;
      }
      if (draft.data) {
        draft.data.publishVersion = (draft.orjData?.publishVersion ?? 0) + 1;
        draft.data.status = true;
      }
      return;
    }
    case COPY_DESKTOP_TO_MOBILE: {
      if (!(draft.data && draft.data.layout)) return;
      // @ts-ignore TODO
      draft.data.layout.contents.forEach((deviceContent: Content) => {
        const desktop = deviceContent.content[DeviceTypes.desktop];
        if (!desktop) return;
        const regex = /\"(gridTemplateColumns)\":\"(.*?)\"/g;
        const heightRegex =
          /\"(height)\":\"(\d*\.?\d+)\s?(px|em|rem|vw|vh+)\"/g;
        const layoutString = JSON.stringify(desktop);

        const newContent = layoutString
          .replace(regex, (_, key) => {
            return `"${key}":"100%"`;
          })
          .replace(heightRegex, (_, key) => {
            return `"${key}":"auto"`;
          });

        const mobile = JSON.parse(newContent);

        mobile.attributes.style = {
          ...mobile.attributes.style,
          maxWidth: '85%',
          width: '100%',
        };

        deviceContent.content[DeviceTypes.mobile] = mobile;
      });
      return;
    }

    case UPDATE_PUBLISH_LOADING_STATUS: {
      const { payload } = action;
      if (draft.orjData) {
        draft.orjData.publishLoadingStatus = payload.publishLoadingStatus;
      }
      if (draft.data) {
        draft.data.publishLoadingStatus = payload.publishLoadingStatus;
      }
      return;
    }

    case UPDATE_HIGHLIGHT_MODE: {
      const { payload } = action;
      draft.highlight.mode = payload.mode;
      return;
    }
    case UPDATE_PAGE_CONTENT: {
      const { payload } = action;
      const { pageId, content } = payload;
      let pageIndex = draft.data?.layout?.contents.findIndex(
        (x) => x.id === pageId,
      );
      if (
        pageIndex !== undefined &&
        pageIndex >= 0 &&
        draft.data &&
        draft.data.layout
      ) {
        draft.data.layout.contents[pageIndex] = { ...content, id: pageId };
      }
      return;
    }
    case RESET_CAMPAIGN_TEASER_TO_ORJDATA: {
      const builderDataTeaserIndex = draft.data?.layout?.contents.findIndex(
        (x) => x.type === StepTypes.TEASER,
      );

      if (
        typeof builderDataTeaserIndex !== 'undefined' &&
        builderDataTeaserIndex >= 0
      ) {
        const teaserOrjData = draft.orjData?.layout?.contents.find(
          (page) => page.type === StepTypes.TEASER,
        );

        if (
          draft.data?.layout?.contents[builderDataTeaserIndex] &&
          teaserOrjData
        ) {
          draft.data.layout.contents[builderDataTeaserIndex] = teaserOrjData;
        }
      }
      return;
    }
    //#endregion
    //#region Target Start
    case SET_TARGET_ERROR: {
      const { payload } = action;
      const { error } = payload;
      if (!draft.data?.targets || !error) return;
      draft.data.targets.isValid = {
        ...draft.data.targets.isValid,
        ...error,
      };
      return;
    }
    case DELETE_TARGET_ERROR: {
      const { payload } = action;
      const { targetName } = payload;
      if (!draft.data?.targets?.isValid || !targetName) return;
      delete draft.data?.targets?.isValid[targetName as TargetsKey];
      if (isEmpty(draft.data?.targets.isValid))
        delete draft.data?.targets.isValid;
      return;
    }
    //#endregion
    //#region  Settings Start
    case LOCAL_UPDATE_CAMPAING: {
      const { payload } = action;
      draft.orjData = payload;
    }
    case SET_INTEGRATIONS: {
      const {
        datas,
        type,
      }: { datas: IntegrationType[]; type: IntegrationEnums } = action.payload;
      if (!draft.orjData || !datas || !type || !draft.orjData[type]) return;
      // @ts-ignore TODO
      draft.orjData[type] = datas;
    }
    case ADD_INTEGRATION: {
      const { data, type }: { data: IntegrationType; type: IntegrationEnums } =
        action.payload;
      if (!draft.orjData || !data || !type || !draft.orjData[type]) return;
      // @ts-ignore TODO
      draft.orjData[type]!.push(data);
      return;
    }
    case UPDATE_INTEGRATION: {
      const { data, type }: { data: IntegrationType; type: IntegrationEnums } =
        action.payload;
      if (!draft.orjData || !data || !type || !draft.orjData[type]) return;
      // @ts-ignore TODO
      const currentIntegration = (draft.orjData[type] as IntegrationType).find(
        (x: IntegrationType) => x.id === data.id,
      );
      if (currentIntegration) {
        currentIntegration.integration = data.integration;
        currentIntegration.name = data.name;
        currentIntegration.listId = data.listId;
        currentIntegration.status = data.status;
        currentIntegration.integrationFields = data.integrationFields;
        // @ts-ignore TODO
        currentIntegration.url = data.url;
      }
      return;
    }
    case DELETE_INTEGRATION: {
      const { payload } = action;
      const { id, type }: { id: number; type: IntegrationEnums } = payload;
      if (
        !draft.orjData ||
        !id ||
        !type ||
        !draft.orjData[type] ||
        !Array.isArray(draft.orjData[type])
      )
        return;
      // @ts-ignore TODO
      draft.orjData[type] = (draft.orjData[type] as IntegrationTypeArray)
        // @ts-ignore TODO
        ?.filter((x: IntegrationType) => x.id !== id);
      return;
    }
    case CHANGE_STATUS_INTEGRATION: {
      const { payload } = action;
      const {
        id,
        status,
        type,
      }: { id: number; status: boolean; type: IntegrationEnums } = payload;

      if (
        !draft.orjData ||
        !id ||
        !type ||
        !draft.orjData[type] ||
        !Array.isArray(draft.orjData[type])
      )
        return;
      // @ts-ignore TODO
      const integration = (draft.orjData[type] as IntegrationTypeArray)?.find(
        (x: IntegrationType) => x.id === id,
      );
      if (integration) integration.status = status;
      return;
    }
    case DISABLE_ALL_INTEGRATIONS: {
      const integrationFieldNames = Object.values(IntegrationEnums);

      integrationFieldNames.forEach((integrationFieldName) => {
        if (
          !draft.orjData ||
          !draft.orjData[integrationFieldName] ||
          !Array.isArray(draft.orjData[integrationFieldName]) ||
          (draft.orjData[integrationFieldName] as IntegrationTypeArray)
            .length === 0
        )
          return;

        // If there is a connected webhook integration, it should be removed.
        if (integrationFieldName === IntegrationEnums.webhook) {
          draft.orjData[integrationFieldName] = [];
          return;
        }

        // set the status of other linked integrations to false if connected
        (draft.orjData[integrationFieldName] as IntegrationTypeArray).forEach(
          (integrationItem) => {
            if (integrationItem.status === true) {
              integrationItem.status = false;
            }
          },
        );
      });
      return;
    }
    case DOMAIN_DELETE: {
      draft.status = ReduxStatus.loading;
      return;
    }
    case DOMAIN_DELETE_SUCCESS: {
      if (!draft.orjData) return;
      if (draft.data) {
        draft.data.domains = draft.data.domains?.filter(
          (x) => x.id !== action.payload.domainId,
        );
        draft.orjData.domains = draft.data.domains;
      }
      draft.status = ReduxStatus.success;
      return;
    }
    case DOMAIN_SELECT: {
      draft.status = ReduxStatus.loading;
      return;
    }
    case DOMAIN_SELECT_SUCCESS: {
      if (!draft.orjData) return;
      if (draft.data) {
        draft.data.domains?.push({
          id: action.payload.id,
          url: action.payload.url,
          verified: action.payload.verified,
          isShopify: action.payload.isShopify,
        });
        draft.orjData.domains = draft.data.domains;
      }

      draft.status = ReduxStatus.success;
      return;
    }
    case UPDATE_AUTO_RESPONDER_REQUEST: {
      draft.status = ReduxStatus.loading;
      return;
    }

    case UPDATE_AUTO_RESPONDER_SUCCESS: {
      const { payload } = action;
      const {
        autoResponderMailBody,
        autoResponderReplyTo,
        autoResponderSenderName,
        autoResponderStatus,
        autoResponderSubject,
      } = payload;
      if (!draft.orjData) return;
      draft.orjData.autoResponderMailBody = autoResponderMailBody;
      draft.orjData.autoResponderReplyTo = autoResponderReplyTo;
      draft.orjData.autoResponderSenderName = autoResponderSenderName;
      draft.orjData.autoResponderStatus = autoResponderStatus;
      draft.orjData.autoResponderSubject = autoResponderSubject;
      draft.status = ReduxStatus.success;
      return;
    }
    case UPDATE_AUTO_RESPONDER_FAILURE: {
      draft.status = ReduxStatus.errored;
      return;
    }
    case SET_THEME: {
      if (!draft.data || !draft.data.layout) return;
      draft.data.layout.style = {
        code: action.payload.code,
        data: action.payload.theme,
      };
      return;
    }
    case SET_PREVIEW: {
      if (!draft.data || !draft.data.layout) return;
      draft.data.layout.preview = {
        code: action.payload.code,
        data: action.payload.theme,
      };
      return;
    }
    case CLEAR_PREVIEW: {
      if (!draft.data || !draft.data.layout) return;
      delete draft.data.layout.preview;
      return;
    }
    case SET_STYLE: {
      if (!draft.data || !draft.data.layout) return;
      const { variable, value } = action.payload;
      if (!variable.includes('.')) {
        draft.data.layout.style.data = {
          ...draft.data.layout?.style.data,
          [variable]: value,
        };
      } else {
        const variables = variable.split('.');
        const lastElement = variables.pop();
        if (!lastElement) return;
        let data;
        if (draft.data.layout.style.data['subText'] === undefined) {
          const subText = ThemeList.find(
            (theme) => theme.code === draft.data?.layout?.style.code,
          )?.data.subText;
          draft.data.layout.style.data['subText'] = subText!;
        }
        if (variables.length === 0) data = draft.data.layout.style.data;
        else data = get(draft.data.layout.style.data, variables.join('.'));
        data[lastElement] = value;
      }

      return;
    }
    case GET_CURRENT_TEMPLATE_SUCCESS: {
      const layout = JSON.parse(action.payload.layout);
      if (layout?.style?.data?.teaser === undefined) {
        const teaser = ThemeList.find(
          (theme) => theme.code === layout.style.code,
        )?.data.teaser;
        const copy = { ...action.payload, layout };
        copy.layout.style.data.teaser = teaser;
        draft.currentTemplate = copy;
      } else {
        draft.currentTemplate = action.payload;
      }
      return;
    }
    //#endregion
    case RESET:
    case RESET_STORE: {
      return initialState;
    }
    case UPDATE_CAMPAIGN_DOMAIN_VERIFIED_STATUS: {
      const { domainId, status } = action.payload;
      const dataDomain = draft.data?.domains?.find((x) => x.id === domainId);
      if (dataDomain) {
        dataDomain.verified = status;
      }
      const orjDataDomain = draft.orjData?.domains?.find(
        (x) => x.id === domainId,
      );
      if (orjDataDomain) {
        orjDataDomain.verified = status;
      }
      return;
    }
    case SET_EMAIL_AUTOMATION_EDIT: {
      const { data } = action.payload;
      if (draft.data) draft.emailAutomationEdit = data;
      return;
    }
    case SET_TOTAL_CAMPAIGN_COUNT: {
      draft.totalCampaignCount = action.payload.count;
      return;
    }
    default: {
      return;
    }
  }
}, initialState);

export default reducer;

//TODO: define type
export const updateProperties = ({
  theObject,
  id,
  propertyName = 'childNodes',
  propertyValue,
}: {
  theObject: any;
  id: string;
  propertyName?: string;
  // eslint-disable-next-line no-undef
  propertyValue?: ChildNode[] | string[];
}) => {
  let newObject;

  if (theObject instanceof Array) {
    newObject = [...theObject];
    for (let i = 0; i < theObject.length; i++) {
      newObject[i] = updateProperties({
        theObject: theObject[i],
        id,
        propertyName,
        propertyValue,
      });
    }
  } else {
    newObject = theObject;
    for (const prop in newObject) {
      if (newObject?.id === id) {
        newObject = {
          ...newObject,
          [propertyName]: propertyValue,
        };
      }
      if (
        newObject[prop] instanceof Object ||
        newObject[prop] instanceof Array
      ) {
        const value = updateProperties({
          theObject: newObject[prop],
          id,
          propertyName,
          propertyValue,
        });

        newObject[prop] = value;
      }
    }
  }

  return newObject;
};

export const SIZE_RATIO = {
  sm: {
    sm: 1,
    md: 1.2,
    lg: 1.4,
  },
  md: {
    sm: 0.833,
    md: 1,
    lg: 1.167,
  },
  lg: {
    sm: 0.715,
    md: 0.857,
    lg: 1,
  },
};

export const updateSizes = ({
  theObject,
  currentSize,
  toSize = 'md',
}: {
  theObject: any;
  currentSize: 'sm' | 'md' | 'lg';
  toSize: 'sm' | 'md' | 'lg';
}) => {
  const regex = /(\d*\.?\d+)\s?(px|em|rem|vw|vh+)/gim;
  const ratio = SIZE_RATIO[currentSize][toSize];

  // layout size update
  theObject.contents.forEach((step: { content: any; appearance: any }) => {
    for (const deviceType in step.content) {
      const layoutString = JSON.stringify(step.content[deviceType]);
      const newContent = layoutString.replace(
        regex,
        (_: any, size: number, unit: string) => {
          const newSize: number = size * ratio;
          return `${Number(newSize.toFixed(2))}${unit}`;
        },
      );
      step.content[deviceType] = JSON.parse(newContent);
      step.appearance.size = toSize;
    }
  });

  // theme size update
  if (theObject.style) {
    const styleString = JSON.stringify(theObject.style.data);
    const newStyleData = styleString.replace(
      regex,
      (_: any, size: number, unit: string) => {
        const newSize: number = size * ratio;
        return `${Number(newSize.toFixed(2))}${unit}`;
      },
    );
    theObject.style.data = JSON.parse(newStyleData);
  }

  return;
};

export const updateFontFamilies = ({
  theObject,
  fontFamily,
}: {
  theObject: any;
  fontFamily: string;
}) => {
  const regex = /\"(fontFamily)\":\"(.*?)\",/g;

  for (const deviceType in theObject.content) {
    const layoutString = JSON.stringify(theObject.content[deviceType]);
    const newContent = layoutString.replace(regex, (_, key) => {
      return `"${key}":"${fontFamily}",`;
    });
    theObject.content[deviceType] = JSON.parse(newContent);
  }
  return;
};

export const updateColor = ({
  theObject,
  oldColor,
  color,
}: {
  theObject: any;
  oldColor: string;
  color: string;
}) => {
  const layoutString = JSON.stringify(theObject);
  const oldRgbCodeArray = hex2rgbArray(oldColor);
  const rgbnNewColor = hex2rgb(color);
  let newContent = layoutString.replace(new RegExp(oldColor, 'g'), color);
  if (oldRgbCodeArray && rgbnNewColor) {
    const rgx = `rgb.${oldRgbCodeArray[0]}\\s?.\\s?${oldRgbCodeArray[1]}\\s?.\\s?${oldRgbCodeArray[2]}.`;
    newContent = newContent.replace(new RegExp(rgx, 'g'), rgbnNewColor);
  }

  return JSON.parse(newContent);
};
//TODO: define type
export const removeProperties = ({
  theObject,
  id,
  propertyName = 'childNodes',
}: {
  theObject: any;
  id: string;
  propertyName?: string;
}) => {
  let newObject;

  if (theObject instanceof Array) {
    newObject = [...theObject];
    let founded;
    for (let i = 0; i < theObject.length; i++) {
      if (newObject[i].id !== id) {
        newObject[i] = removeProperties({
          theObject: theObject[i],
          id,
          propertyName,
        });
      } else {
        founded = i;
      }
    }
    if (founded !== undefined) {
      newObject.splice(founded, 1);
    }
  } else {
    newObject = theObject;
    for (const prop in newObject) {
      if (newObject?.id === id) {
        delete newObject[propertyName];
      }
      if (
        newObject[prop] instanceof Object ||
        newObject[prop] instanceof Array
      ) {
        const value = removeProperties({
          theObject: newObject[prop],
          id,
          propertyName,
        });

        newObject[prop] = value;
      }
    }
  }
  return newObject;
};
//TODO: define type
export const getObject = (theObject: any, id: string): any => {
  let result = null;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = getObject(theObject[i], id);
      if (result) {
        break;
      }
    }
  } else {
    for (const prop in theObject) {
      if (theObject.id && theObject.id === id) {
        return theObject;
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        result = getObject(theObject[prop], id);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
};

export const getParentObject = (theObject: any, id: string): any => {
  let result = null;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = getParentObject(theObject[i], id);
      if (result) {
        break;
      }
    }
  } else {
    if (theObject.childNodes?.some((x: any) => x.id === id)) return theObject;
    for (const prop in theObject) {
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        result = getParentObject(theObject[prop], id);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
};

export const getObjectCountByNodeName = (
  theObject: any,
  nodeName: string,
): any => {
  let count = 0;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      count += getObjectCountByNodeName(theObject[i], nodeName);
    }
  } else {
    for (const prop in theObject) {
      if (theObject.id && theObject.nodeName === nodeName) {
        return 1;
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        count += getObjectCountByNodeName(theObject[prop], nodeName);
      }
    }
  }
  return count;
};

export const generateAllIds = ({
  theObject,
  ids,
}: {
  theObject: any;
  ids: { status: boolean; data: { oldId: string; newId: string }[] };
}) => {
  let newObject;
  if (theObject instanceof Array) {
    newObject = [...theObject];
    for (let i = 0; i < theObject.length; i++) {
      newObject[i] = generateAllIds({
        theObject: theObject[i],
        ids,
      }).newObject;
    }
  } else {
    newObject = theObject;
    for (const prop in newObject) {
      if (newObject?.id !== 'canvas' && prop === 'attributes') {
        if (!ids.status) {
          const newId = hash();
          const oldId = newObject.id;
          const id = {
            oldId,
            newId,
          } as { oldId: string; newId: string };
          newObject.id = newId;
          ids.data.push(id);
        } else {
          const oldId = newObject.id;
          newObject.id = ids.data.find((x) => x.oldId === oldId)?.newId;
        }
        newObject.attributes = Object.assign({}, newObject.attributes);
        newObject['attributes'][
          'id'
        ] = `${newObject['nodeName']}-${newObject.id}`;
        newObject['attributes'][
          'className'
        ] = `${newObject['nodeName']}-p_${newObject.id}`;
      }
      if (
        newObject[prop] instanceof Object ||
        newObject[prop] instanceof Array
      ) {
        const { newObject: _newObject, ids: _ids } = generateAllIds({
          theObject: newObject[prop],
          ids,
        });
        newObject[prop] = _newObject;
        ids = _ids;
      }
    }
  }
  return { newObject, ids };
};

const replaceValues = ({
  theObject,
  oldValues,
  newValues,
}: {
  theObject: any;
  oldValues: string[];
  newValues: string[];
}): any => {
  let newObject;
  if (theObject instanceof Array) {
    newObject = [...theObject];
    for (let i = 0; i < theObject.length; i++) {
      newObject[i] = replaceValues({
        theObject: theObject[i],
        oldValues,
        newValues,
      }).newObject;
    }
  } else {
    newObject = theObject;
    for (const prop in newObject) {
      if (theObject[prop])
        if (
          typeof theObject[prop] === 'string' ||
          typeof theObject[prop] === 'number'
        ) {
          const value = theObject[prop];
          const foundedIndex = oldValues.indexOf(value);
          if (foundedIndex !== -1) theObject[prop] = newValues[foundedIndex];
        }
      if (
        newObject[prop] instanceof Object ||
        newObject[prop] instanceof Array
      ) {
        const { newObject: _newObject } = replaceValues({
          theObject: newObject[prop],
          oldValues,
          newValues,
        });
        newObject[prop] = _newObject;
      }
    }
  }
  return { newObject };
};

export const getAllColorsFromString = (layoutData: any) => {
  if (!layoutData) return null;

  const layoutString = JSON.stringify(layoutData);
  const hexRgx = /#[0-9a-f]{8}|#[0-9a-f]{6}|#[0-9a-f]{4}|#[0-9a-f]{3}/gi;
  const rgbRgx = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi;
  const hexColorArr = layoutString.match(hexRgx);
  const rgbColorArr = layoutString.match(rgbRgx);
  const colorArr = [];
  if (hexColorArr) colorArr.push(...hexColorArr);
  if (rgbColorArr) colorArr.push(...rgbColorArr.map((x) => rgbToHex(x)));
  return uniq(colorArr);
};

export const getAllColors = (theObject: any, colors: Array<string>): any => {
  let result;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = getAllColors(theObject[i], colors).result;
      if (result) {
        break;
      }
    }
  } else {
    for (const prop in theObject) {
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        if (prop === 'style') {
          //TODO
          if (theObject[prop]['borderTopColor']) {
            colors.push(theObject[prop]['borderTopColor']);
          }
        }
        result = getAllColors(theObject[prop], colors).result;
        if (result) {
          break;
        }
      }
    }
  }
  return { result, colors };
};

const findAndReplaceByNodeName = <T extends Record<string, any>>(
  theObject: T,
  typeMap: {
    [key: string]: (node: any) => any;
  },
): T => {
  for (const property in theObject) {
    if (
      Object.prototype.hasOwnProperty.call(theObject, property) &&
      typeof theObject[property] === 'object'
    ) {
      if (
        Object.prototype.hasOwnProperty.call(theObject[property], 'nodeName') &&
        typeMap[theObject[property].nodeName]
      ) {
        const migrationFunction = typeMap[theObject[property].nodeName];
        if (typeof migrationFunction === 'function') {
          theObject[property] = migrationFunction(theObject[property]);
        }
      }
      findAndReplaceByNodeName(theObject[property], typeMap);
    }
  }
  return theObject;
};
